/****************************** App ******************************/

.app {
	font-size: app-font-sizes(reg);
	color: app-text-colors(base);

	&__mail-send {
		flex-basis: 75%;
	}

	/****************************** Heading ******************************/

	&-heading {
		.df-text,
		.cl-text {
			font-weight: font-weights(medium);
			font-size: app-font-sizes(heading);
			line-height: 28px;
		}

		.df-text {
			color: app-text-colors(base);
		}

		.cl-text {
			color: app-text-colors(primary);
		}

		.des-text,
		.info-text {
			line-height: 22px;
		}

		.des-text {
			color: app-text-colors(desc);
		}

		.info-text {
			color: app-text-colors(base);
		}
	}

	/****************************** Filter ******************************/

	&-filter {
		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: app-text-colors(primary);
			border-color: app-text-colors(primary);
		}
		.ant-checkbox-wrapper {
			margin: 0;
		}
		.ant-input-group-wrapper {
			width: auto;
		}
		@include xs {
			> div {
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
			}
			.line-divider {
				display: none;
			}
		}
		& .heading {
			h5 {
				white-space: nowrap;
				font-weight: font-weights(medium);
				line-height: 24px;
				font-size: app-font-sizes(md);
				margin: 0;
			}
		}
		.ant-tag {
			color: $color-app-base;
			text-align: center;
			border-radius: 20px;
			width: 30px;
			height: 20px;
			margin-left: 4px;
		}

		.app-label {
			line-height: 22px;
			font-weight: 500;
			margin-right: 4px;
		}

		.ant-badge-count {
			background-color: #e2fbfa;
			color: $color-app-base;
		}

		.ant-form-item {
			margin: 0;
		}
		.ant-form-item-label > label::after {
			display: none !important;
		}
		&__form {
			&--clear,
			&-admin--search,
			&-admin--clear {
				display: flex;
			}
			&--search {
				justify-content: flex-end;
				.ant-form-item {
					float: right;
				}
			}
		}

		&--admin {
			.app-filter__form {
				&--search {
					justify-content: flex-end;
				}
			}
		}
	}

	/****************************** List ******************************/

	&-list {
		.ant-table-cell {
			color: app-text-colors(base);
			line-height: 1.5;
		}
		.ant-table-thead > tr > th {
			background: #ebebeb;
			font-weight: font-weights(medium);
			padding: 7px 16px;
		}
		.text-disabled {
			color: app-text-colors(desc);
		}

		.ant-pagination-total-text {
			float: left;
			margin-right: auto;
			order: -2;
		}
		.ant-pagination-options {
			order: -1;
			margin-right: 16px;
		}
	}
	.refresh-btn {
		cursor: pointer;
		line-height: 22px;
		color: #707070;
		transition: color 0.2s ease;
		.icon-redo {
			transform: rotate(-45deg);
		}
		svg {
			font-size: 12px;
			margin-bottom: 1px;
		}

		&:hover {
			color: $color-app-primary;
		}
	}
	&-info__desc-typo {
		margin-bottom: 0 !important;
	}
}

.warn-box {
	border-left: 2px solid #ff4559;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.secret-key {
	padding: 6px 12px;
	background: #f4f6f8;
	border: 1px solid #c7c7c7;
	border-radius: 2px;
	margin: 0 !important;
	.copyable {
		color: #22817e;
	}
	& > div {
		float: right;
		margin: auto;
	}
	svg {
		&:hover {
			color: rgb(218, 129, 129);
		}
	}
}
.ant-tooltip-inner {
	width: fit-content !important;
}
.data-link {
	color: $color-app-link;
}

.white-box-no-mg {
	margin: 0 !important;
}

.ant-scroll-number-only-unit {
	color: $color-app-base;
	font-size: 12px;
}

.permission-chk {
	span {
		&:last-of-type {
			padding-right: 0 !important;
		}
	}
}

/****************************** App Modal ******************************/

.app-modal {
	.ant-modal-content {
		padding: 16px 24px;
		.ant-modal-header {
			padding: 0 0 10px 0;
			border-color: $color-app-modal-border;
			.ant-modal-title {
				@include app-modal-font(14, 22, 500, $color-app-primary);
			}
		}

		.ant-modal-body {
			padding: 12px 0 0 0;
			form {
				width: 100%;
				height: 100%;
				.upload-field {
					display: flex;
					flex-direction: column;
					justify-content: center;
					@include app-modal-font(14, 22, 400, rgba(0, 0, 0, 0.451));
				}

				.ant-form-item {
					margin-bottom: 12px;
					.ant-form-item-label {
						text-align: left;
						label {
							span {
								@include app-modal-font(14, 20, 400, $color-app-base);
							}
						}
					}

					.ant-form-item-required {
						&:before {
							display: none !important;
						}
						&:after {
							display: inline-block;
							margin-right: 4px;
							color: #ff4d4f;
							font-size: 14px;
							line-height: 1;
							content: "*" !important;
						}
						@include xs {
							&::after {
								display: none !important;
							}
						}
					}
					.ant-form-item-control-input-content > input {
						border-radius: 4px;
					}
					@include sm {
						.ant-form-item-label > label::after {
							display: none !important;
						}
					}
				}
				& > div::last-of-type {
					margin-bottom: 0 !important;
				}
				input {
					@include app-modal-form-input;
					&::placeholder {
						@include app-base-font-placeholder;
					}
				}
				textarea {
					@include app-modal-font(14, 20, 400, $color-app-base);
					border-radius: 2px;
				}

				.ant-form-item-control {
					.ant-upload {
						> div {
							height: 100%;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
						}
						position: relative;
						> .info {
							&::before {
								position: absolute;
								z-index: 1;
								width: 100%;
								height: 100%;
								background-color: #00000080;
								opacity: 0;
								transition: all 0.3s ease;
								content: " ";
							}

							&:hover {
								&::before {
									opacity: 1;
								}
							}
						}
						.upload-action__btn {
							display: flex;
							// justify-content: space-evenly;
							flex-direction: inherit;
							align-items: center;
							position: absolute;
							top: 50%;
							left: 50%;
							z-index: 10;
							white-space: nowrap;
							transform: translate(-50%, -50%);
							opacity: 0;
							transition: all 0.3s;
							&:hover {
								opacity: 1;
							}

							.anticon {
								svg {
									font-size: 18px;
								}
								&:last-child {
									&:hover {
										svg {
											color: #ff4559;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.ant-modal-footer {
			padding-top: 0;
			padding-right: 0;
			padding-bottom: 0;
			border: none;
		}
	}

	&-secret {
		.ant-modal-confirm-title {
			border-bottom: 1px solid #c7c7c7;
			color: $color-app-primary;
		}
	}
	&-confirm {
		.ant-modal-content {
			padding: 16px 24px;
		}
		.ant-modal-title {
			color: $color-app-primary;
		}
		.ant-modal-body {
			padding: 12px 0;
			p {
				font-size: 14px;
				line-height: 20px;
				color: #000000;
			}
		}
		.ant-modal-header {
			padding: 8px 0;
			border-color: #c7c7c7;
		}
		.ant-modal-footer {
			padding: 0;
			border: none;
		}
	}
}

.cl-black {
	color: $color-app-base;
}

.need-required {
	position: relative;
	&:before {
		display: none !important;
	}
	&:after {
		position: absolute;
		display: inline-block;
		color: #ff4d4f;
		font-size: 14px;
		line-height: 1;
		top: 1px;
		right: -12px;
		content: "*";
	}
}

.line-divider {
	background-color: #ebebeb;
	height: 1px;
	width: 100%;
	margin: 0 16px;
}

.clear {
	line-height: 22px;
	gap: 6px;
	color: app-text-colors(desc);
	border: none;
	box-shadow: none;
	&:hover {
		cursor: pointer;
	}
	.anticon-redo {
		transition: 5s all ease;
		transform: rotate(-80deg);
		&:hover {
			transform: rotate(180deg);
		}
	}
}

.app__detail {
	& .error-page {
		transform: translate(-50%, 50%);
	}
}

@include xs {
	.app-filter__form {
		.ant-form-item-label {
			flex-basis: 90px !important;
		}
		.ant-form-item-control {
			flex-basis: calc(100% - 90px) !important;
		}

		&-admin--search {
			justify-content: flex-end;
		}
	}
}

@include sm {
	.app-filter__form {
		&--search {
			display: flex;
		}
		&-admin--search {
			justify-content: flex-end;
		}

		&-admin--clear {
			justify-content: flex-start;
		}
	}
}

@include lg {
	.app-filter__form {
		.ant-form-item-label {
			flex-basis: auto;
		}
	}
}

.ant-layout-header {
	line-height: unset;
}

.pending-color {
	color: #FAAD14;
}