.ant-input:focus {
	&:focus {
		border-color: #d9d9d9 !important;
		box-shadow: none !important;
	}
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
	border-color: #d9d9d9 !important;
	box-shadow: none !important;
}

.anticon-dashboard,
.anticon-audit {
	svg {
		color: $color-app-primary;
	}
}

.ant-menu-item {
	&::after {
		border-color: $color-app-primary !important;
	}
}
