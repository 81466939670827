@use "sass:math";

/*=====================================COMMON CLASS=====================================*/
/* PADDING */
$class: "pd-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding: #{$i}px;
  }
}

/* PADDING TOP */
$class: "pd-t-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-top: #{$i}px;
  }
}

/* PADDING RIGHT */
$class: "pd-r-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-right: #{$i}px;
  }
}

/* PADDING BOTTOM */
$class: "pd-bt-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-bottom: #{$i}px;
  }
}

/* PADDING LEFT */
$class: "pd-l-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-left: #{$i}px !important;
  }
}

/* PADDING TOP BOTTOM */
$class: "py-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
}

/* PADDING LEFT RIGHT */
$class: "px-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
}

$class: "mg-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin: #{$i}px;
  }
}

$class: "mg-ipt-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin: #{$i}px !important;
  }
}

$class: "mg-t-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-top: #{$i}px;
  }
}

$class: "mg-r-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-right: #{$i}px;
  }
}

$class: "mg-bt-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-bottom: #{$i}px !important;
  }
}

$class: "mg-l-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-left: #{$i}px;
  }
}

$class: "mx-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
}

$class: "my-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
}

// border width
$class: "border-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    border-width: #{$i}px;
  }
}

//font-size
$class: "font-size-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    font-size: #{$i}px;
  }
}

//line-height
$class: "line-h-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    line-height: #{$i}px;
  }
}

//top
$class: "top-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    top: #{$i}px;
  }
}

//top
$class: "bottom-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    bottom: #{$i}px;
  }
}

//left
$class: "bottom-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    bottom: #{$i}px;
  }
}

//left
$class: "right-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    right: #{$i}px;
  }
}

.resize-none {
  resize: none;
}

.line-height-normal {
  line-height: normal;
}

.no-border {
  border: none;
}

.no-radius {
  border-radius: 0;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.flr {
  float: right;
}

.fll {
  float: left;
}

.clearfix {
  clear: both;
}

.text-yellow {
  color: #f39c12 !important;
}

.text-danger {
  color: #ff4d4f;
}

.text-blue {
  color: #2a7ee5;
}

.uppercase {
  text-transform: uppercase;
}

.hide {
  display: none;
}

.text-al-r {
  text-align: right;
}

.text-al-l {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.bold-400 {
  font-weight: 400;
}

.color-white {
  color: white;
}

.color-red {
  color: red;
}

.bg-red {
  background-color: red;
}

.bg-white {
  background-color: white;
}

.bg-grey {
  background-color: #efeeee;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.width-100pc {
  width: 100%;
}

.list-unstyled {
  margin: 0;
  padding: 0;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-20px {
  font-size: 20px;
}

.width-100px {
  width: 100px;
}

.width-150px {
  width: 150px;
}

.width-200px {
  width: 200px;
}

.width-270px {
  width: 270px;
}

.width-300px {
  width: 300px;
}

.table-responsive {
  overflow-x: auto;
  clear: both;
}

.v-text-top {
  vertical-align: text-top;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-none {
  display: none !important;
}

.nowrap-css {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  display: inline-block;
}

.font-weight-word {
  font-weight: 600;
}

.breadcrumb-wrap-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.icon-quick-edit {
  font-size: 12px;
  color: #505050;
}

.icon-check-quick-edit {
  margin-left: 10px;
}

.blue-link {
  color: #1890ff;
}

.fix-css-tabs-nav {
  .ant-tabs-nav {
    padding-left: 20px;
  }
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

// flex
.flex {
  display: flex;
}

// flex direction
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-hitem {
  flex: 1 1 0;
}

//justify content
.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

//Justify Items
.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

//Align Content
.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

//Align Items
.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

//Align Self
.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

//border color
.border {
  border: #b3ceed 2px solid;
}

.icon-active {
  background-color: #00ff00;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 10px;
}

.icon-inactive {
  background-color: red;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 10px;
}

//font-style

.italic {
  font-style: italic;
}

.cursor-pointer {
  cursor: pointer;
}

.h-200 {
  height: 200px;
}

.text-maxW-250 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
}

.text-maxW-300 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.h-100pc {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
}

.negative-money {
  color: red;
}

.positive-money {
  color: green;
}

.transaction-code {
  font-size: 12px;
}

.default-money-total {
  font-size: 1.2em;
}

.first-letter-uppercase:first-letter {
  text-transform: uppercase;
}

.box-account-total-amount {
  font-size: 12px;
}

.empty-account {
  padding: 2em;
  color: red;
}

.font-12px {
  font-size: 12px;
}

.font-10px {
  font-size: 10px;
}

.h-scroll {
  overflow-x: auto;
}

.link--no-blue {
  color: inherit;
}

.has-comma {
  &:after {
    content: ",";
    display: inline;
    margin: 0 5px 0 0;
  }
}

.dirty-balance {
  background-color: #eb2f96;
  color: #fff;
  padding: 3px;
}

.form-item--no-label {
  margin-bottom: 10px;

  .ant-form-item-label {
    display: none;
  }
}

.img-cover {
  object-position: top center;
  object-fit: cover;
}

.transaction-remark {
  font-size: 0.7em;
  color: red;
  font-style: italic;
}

.voucher-invoice-attachment-list {
  overflow: hidden;

  .voucher-invoice-attachment {
    width: 90px;
    height: 90px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    .voucher-invoice-attachment-img {
      width: 100%;
      height: 100%;
    }
  }
}

.d-plex {
  display: flex;
}

// Adding 17 Dec 2021 for app common

//font override
.app-font-md {
  // font-family: $font-app-base !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

$class: "rad-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    border-radius: #{$i}px !important;
  }
}

.flex-start {
  justify-content: flex-start;
}

.mg-l-imp-0 {
  margin-left: 0 !important;
}

$class: "gap-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    gap: #{$i}px;
  }
}

$class: "flex-basis-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-basis: #{$i}px;
  }
}

.flex-basis-190 {
  flex-basis: 190px;
}

$class: "flex-basis-pc-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-basis: percentage($number: math.div($i, 100));
  }
}

$class: "flex-shrink-";
@for $i from 0 through 60 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-shrink: #{$i}px;
  }
}

$class: "flex-grow-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-grow: #{$i};
  }
}

$class: "width-calc-";
@for $i from 0 through 100 {
  // for each $pd-t-#{i}
  .#{$class}#{$i} {
    flex-basis: calc(100% - #{$i}px);
  }
}

.full-col {
  grid-column: 1 / -1;
}

.btn-xs {
  width: fit-content;
}

.white-nowrap {
  white-space: nowrap;
}

.mr-auto {
  float: right;
  margin-left: auto;
}

.image-auto {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.width-72 {
  min-width: 71px !important;
  width: 71px !important;
}

.text-danger {
  color: #ff4559;
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: $color-app-disabled;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.line-1 {
  width: 1px;
  background-color: #c7c7c7;
  height: 16px;
}

.overflow {
  overflow: auto;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-width-100pc {
  max-width: 100%;
}

.max-width-500 {
  max-width: 500px;
}

$class: "truncate-multiline-";
@for $i from 0 through 100 {
  .#{$class}#{$i} {
    -webkit-line-clamp: $i;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.w-500 {
  width: 500px;
}

.w-400 {
  width: 400px;
}

.unset-text-wrap {
  white-space: unset;
}

.pointer {
  cursor: pointer;
}

.badge-gray-yellow {
  .ant-badge-count {
    background-color: rgb(209 209 209 / 60%)
  }

  .ant-scroll-number-only-unit {
    color: #1e1e1e;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}
