/*//Border Radius*/
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

/*//Center Block*/
@mixin center-block($width) {
  width: $width;
  display: block;
  margin: 0 auto;
}

/*// Single side border-radius*/
@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

/*//Triangle*/
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
  /*@include pseudo($pos: $position);*/
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round( $size / 2.5 );
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round( $size / 2.5 );
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if  $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

/*//Box shadow*/
@mixin box-shadow ($arg) {
  box-shadow: $arg;
  -moz-box-shadow: $arg;
  -webkit-box-shadow: $arg;
}
//box shadow
@mixin boxshadow($top, $left, $blur, $spread, $color, $inset:"") {
  -webkit-box-shadow:$top $left $blur $spread $color #{$inset};
  -moz-box-shadow:$top $left $blur $spread $color #{$inset};
  box-shadow:$top $left $blur $spread $color #{$inset};
}

//box shadow
@mixin boxshadow($top, $left, $blur, $spread, $color, $inset:"") {
  -webkit-box-shadow:$top $left $blur $spread $color #{$inset};
  -moz-box-shadow:$top $left $blur $spread $color #{$inset};
  box-shadow:$top $left $blur $spread $color #{$inset};
}


/*Placeholders*/
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin placeholder-color($color) {
  &.placeholder {
    color: $color
  }

  &:-moz-placeholder {
    color: $color
  }

  &::-webkit-input-placeholder {
    color: $color
  }

  &:-ms-input-placeholder {
    color: $color
  }
}

/*Truncate*/
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*//Clearfix*/
@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/*--------------------------------
	Responsive ratio
	Used for creating scalable elements that maintain the same ratio
	example: .element { @include responsive-ratio(400, 300); }
*/
@mixin responsive-ratio($x,$y, $pseudo: false) {
  $padding: unquote( ( $y / $x ) * 100 + '%' );

  @if $pseudo {
    &::before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

/*//Transition*/
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  transition: $args;
}

/*// Browser Prefixes*/
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  transform: $transforms;
}

/*// Rotate*/
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

/*// Scale*/
@mixin scale($scale) {
  @include transform(scale($scale));
}

/*// Translate*/
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

/*// Skew*/
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

/*// Transform Origin*/
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  transform-origin: $origin;
}

/*//Backgroud Gradien*/
@mixin linear-gradient ($from, $to) {
  background-color: $from;
  background-image: -webkit-linear-gradient(top, $from, $to); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image:         linear-gradient(to bottom, $from, $to); /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

/*//Color RGBA*/
@mixin transparent-backgound($color: #000, $value:0.5){
  $start: "rgb(";
  $end: ")";
  background: #{$start}red($color), green($color), blue($color)#{$end};
  background: rgba($color, $value);
}