.app-info {
	&__name-typo {
		color: #2a9f9c;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
		margin-bottom: 0 !important;
		max-width: 300px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include xs {
			max-width: 250px;
			font-size: 16px;
		}
		.ant-input {
			position: relative;
			top: 0.5em;
			left: 0.8em;
			bottom: 0;
		}
	}
	&__desc-typo {
		.ant-input {
			bottom: 0;
		}
	}
}
