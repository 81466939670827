@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700&amp;subset=vietnamese');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
@import url('https://use.fontawesome.com/releases/v5.0.13/css/solid.css');
@import url('https://use.fontawesome.com/releases/v5.0.13/css/regular.css');
@import url('https://use.fontawesome.com/releases/v5.0.13/css/fontawesome.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

@import url('https://use.fontawesome.com/releases/v5.0.13/css/solid.css');
@import url('https://use.fontawesome.com/releases/v5.0.13/css/regular.css');
@import url('https://use.fontawesome.com/releases/v5.0.13/css/fontawesome.css');

$font-base: 'Roboto', sans-serif;
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: $font-base;
}

* {
    font-family: $font-base;
}

ul {
    li {
        list-style: none;
    }
}
html,
body #root,
#main-layout {
    height: 100% !important;
    min-height: 100% !important;
}

input {
    &:focus {
        outline: none;
    }
}
