$trans: all 0.2s ease;
$b-shadow: 0px 2px 14px #e6e6e6;

.btn {
	color: $color-app-primary;
	border-color: $color-app-border;
	border-radius: 4px !important;
	background: #fff;
	&:active {
		background-color: $color-app-primary-m;
		box-shadow: 0px 0px 0px 3px #e2fbfa;
	}

	&:hover {
		background-color: $color-app-primary-m;
		color: $color-app-white;
		border-color: $color-app-primary-m;
	}

	&:disabled {
		background-color: #ebebeb;
		color: #bdbdbd;
	}
	span {
		font-size: 14px;
		line-height: 22px;
	}
	&.active {
		color: $color-app-white;
		background-color: $color-app-primary-m;
		border-color: $color-app-primary-m;
		&:hover {
			background-color: $color-app-white;
			color: $color-app-primary;
			border-color: $color-app-border;
		}
	}

	&.cancel {
		color: $color-app-base;
		border-color: #c7c7c7;
		span {
			font-size: 14px;
			line-height: 22px;
		}
		&:hover {
			background-color: $color-app-primary-m;
			color: $color-app-white;
			border-color: $color-app-primary-m;
		}
	}
}

.template {
	display: flex;
	padding: 16px 12px;
	width: 100%;
	flex-direction: column;
	@include xs {
		.clear {
			display: none;
		}
	}
	@include sm {
		padding: 12px;
	}
	@include lg {
		flex-direction: row;
		padding: 16px;
	}
	.ant-table-cell {
		color: app-text-colors(base);
		line-height: 1.5;
		white-space: nowrap;
	}
	.ant-table-thead > tr > th {
		background: #ebebeb;
		font-weight: font-weights(medium);
		padding: 7px 16px;
	}
	&-temp {
		.btn {
			@include border-radius(4px);
			padding: 5px 16px;
		}
		.ant-form-item {
			margin-right: 0;
		}
		.ant-input-affix-wrapper {
			border-radius: 4px;
			// max-width: 183px;
		}
		.ant-form-item-label {
			font-weight: 500;
			font-size: 14px;
			line-height: 22px;
			color: $color-app-base;
		}
		&-input {
			flex-wrap: wrap;
			// gap: 12px;

			@include xs {
				.ant-form-item {
					width: 100%;
					// gap: 12px;
					&:first-child {
						margin-bottom: 12px;
					}
				}
				.ant-form-item-label {
					padding-bottom: 0;
					&::after {
						content: ":";
						position: relative;
						top: -0.5px;
						margin: 0 8px 0 2px;
					}
				}
			}

			@include sm {
				.ant-form-item {
					width: 100%;
					// gap: 12px;
					.ant-form-item-label {
						margin-right: 8px;
					}
					&:last-child {
						margin-top: 12px;
						// gap: 8px;
						.ant-form-item-label {
							margin-right: 4px;
						}
					}
				}
			}

			@include lg {
				gap: 16px;
				.ant-form-item {
					width: unset;
					&:last-child {
						margin-top: 0px;
						// gap: 8px;
					}
				}
			}
			&-clear {
				margin-top: 16px;
				.clear {
					display: none;
				}
				@include lg {
					margin-top: 0;
				}
				@include xs {
					width: 100%;
					.clear {
						display: block;
					}
					.ant-form-item-control-input-content {
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}
			}
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: app-text-colors(primary);
			border-color: app-text-colors(primary);
		}
		.ant-checkbox-wrapper {
			margin: 0;
		}

		&.admin,
		&.member {
			& .user-actions {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.ant-row {
					margin: 0;
				}
				& > div {
					flex-basis: 48%;
				}
				&__clear {
					line-height: 22px;
					color: #707070;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}

		&.admin {
			.template-temp-input {
				justify-content: space-between;
			}
			.user-actions {
				gap: 16px;
			}
		}

		&.member {
			& .user-actions {
				.ant-row {
					margin: 0;
				}
				& > div {
					flex-basis: 48%;
				}
			}

			.ant-picker {
				width: 100%;
			}
		}
	}

	&-box {
		background-color: $base-text-color;
		box-shadow: $b-shadow;
		border-radius: 4px;
		padding: 16px 12px;
		border-radius: 4px;
		flex-basis: 75%;
		@include md {
			padding: 16px 24px;
		}
		.ant-divider {
			margin: 12px 0;
			@include md {
				margin: 24px 0;
			}
		}
		.line-divider {
			display: none;
			@include sm {
				display: block;
			}
		}
		&-head {
			display: flex;
			align-items: flex-start;
			&-img {
				margin-right: 12px;
				img {
					width: 56px;
					height: 56px;
					@include md {
						width: 64px;
						height: 64px;
					}
					object-fit: cover;
				}
			}
			&-title {
				color: #2a9f9c;
				font-weight: 500;
				font-size: 20px;
				line-height: 28px;
			}
			&-desc {
				width: 100%;
				font-size: 14px;
				line-height: 22px;
				color: $color-app-base;
				word-break: break-word;
				@include xs {
					margin-top: 0;
				}
				.ant-typography-edit-content {
					outline: none;
				}

				.app-info__desc-typo {
					width: 80%;
					@include xs {
						width: 100%;
					}
				}

				textarea {
					height: 40px;
					margin-left: 10px;
				}
			}
			&__content {
				@include xs {
					display: flex;
					flex-direction: column;
					gap: 4px;
					.btn {
						width: fit-content;
					}
				}
			}
		}
		&-info {
			&-text {
				display: flex;
				justify-content: space-between;
			}
			h3 {
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: $color-app-base;
				margin: 0;
			}
			span {
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
			}
			button {
				font-size: 14px;
				line-height: 22px;
				color: #008df9;
				height: unset;
				padding: unset;
				transition: $trans;
				&:hover {
					color: $color-app-primary-m;
				}
			}
		}

		&-table {
			&-head {
				display: flex;
				justify-content: space-between;
				margin-bottom: 12px;
				align-items: center;
				white-space: nowrap;
				@include xs {
					margin-bottom: 10px;
					gap: 12px;
					align-items: flex-start;
					flex-direction: column;
				}

				h5 {
					margin-bottom: 0;
				}

				.ant-tag {
					color: $color-app-base;
					border-radius: 20px;
					text-align: center;
					width: 30px;
					height: 20px;
					margin-left: 4px;
				}
			}

			&-list {
				overflow-x: auto;
				overflow-y: auto;
				max-height: 430px;
				.ant-table-cell {
					max-width: 160px;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&.mail-send {
					.ant-table-cell {
						white-space: unset;
					}
					th.ant-table-cell {
						white-space: nowrap;
					}
				}
			}
			.badge-table {
				display: flex;
				align-items: center;
				.ant-badge-status-text {
					white-space: nowrap;
				}
			}
			.role-owner {
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
				color: #22817e;
				&-nv {
					cursor: pointer;
					display: flex;
					align-items: center;
					width: 100%;
					height: 32px;
					span {
						font-size: 14px;
						line-height: 22px;
						margin-right: 6px;
					}
					.anticon.anticon-form {
						cursor: pointer;
						font-size: 10px;
						line-height: 12px;
						color: $color-app-primary;
					}
				}
				&-select {
					height: 100%;
					// .ant-select-selector {
					//     height: 22px;
					//     width: 70px;
					// }
				}
			}
			.ant-table-cell {
				font-size: 14px;
				line-height: 22px;
				.btn-table-delete {
					cursor: pointer;
					transition: color 0.3s ease;

					&:hover {
						color: $color-app-primary-m;
					}
				}
			}
			.info-icon {
				margin-left: 5px;
				color: #707070;
				font-size: 10px;
				line-height: 12px;
			}
			.ant-table-thead > tr > th {
				background-color: #ebebeb;
				color: $color-app-base;
				font-weight: 500;
				font-size: 14px;
				line-height: 22px;
			}
		}
	}

	&-wrapper-tabs {
		flex-basis: 25%;
		flex-grow: 0;
		@include xs {
			margin-bottom: 12px;
		}
		@include sm {
			margin-bottom: 12px;
			// margin-right: 12px;
		}
		@include lg {
			margin-right: 12px;
		}
		@include xl {
			margin-bottom: unset;
			margin-right: 16px;
		}
	}

	&-tabs {
		display: flex;
		flex-direction: row;
		background-color: #fff;
		border-radius: 4px 4px 0px 0px;
		padding: unset;
		box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);

		@include lg {
			flex-direction: column;
			padding: 16px;
			box-shadow: 0px 2px 14px #e6e6e6;
			border-radius: 4px;
			grid-gap: 12px;
		}
		@include xl {
			padding: 16px 24px;
		}
		&-nav {
			padding: 16px 24px;
			background-color: $base-text-color;
			box-shadow: $b-shadow;
			margin-bottom: 0 !important;
			border-radius: 4px;
			max-height: 160px;
			min-height: 160px;
		}
		.tab-item {
			display: flex;
			padding: 9px 12px !important;
			&:hover {
				color: $color-app-primary;
			}
			@include lg {
				padding: 5px 12px !important;
				&:hover {
					background-color: $color-app-primary;
					color: $color-app-white;
				}
			}
			@include lg {
				padding: 5px 16px !important;
			}
			color: $color-app-base;
			transition: $trans;
			border-radius: 4px;
			cursor: pointer;

			&-icon {
				font-weight: 900;
				font-size: 14px;
				line-height: 16px;
				margin-right: 8px;
				color: inherit;
				display: none;

				@include lg {
					margin-right: 15px;
					display: block;
				}
			}
			&-text {
				font-weight: 500;
				font-size: 14px;
				line-height: 19px;
				color: inherit;
				white-space: nowrap;
			}
			&.active {
				color: $color-app-primary;
				position: relative;
				transition: all 0.3s ease;
				&::after {
					content: "";
					position: absolute;
					width: 100%;
					height: 2px;
					bottom: 0;
					transition: all 0.3s ease;
					left: 0;
					background-color: #2a9f9c;
				}

				@include lg {
					background-color: $color-app-primary;
					color: $color-app-white;
					&::after {
						display: none;
					}
				}
			}
		}
	}

	&-add {
		margin: 0 auto;
		width: 100%;

		@include sm {
			max-width: 1110px;
		}

		.ant-form-item-required {
			&:before {
				display: none !important;
			}
			&:after {
				display: inline-block;
				margin-right: 4px;
				color: #ff4d4f;
				font-size: 14px;
				line-height: 1;
				content: "*" !important;
			}
		}
		&-leave {
			font-size: 14px;
			line-height: 22px;
			color: $color-app-primary;
			.ant-form-item-label {
				padding-bottom: 0;
				& > label::after {
					display: none !important;
				}
			}
		}
		&-title {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			margin: 12px 0;
			color: $color-app-base;
		}
		&-leave {
			margin-bottom: 12px;
			.ant-form-item-label {
				display: flex;
				justify-content: flex-start;
			}
		}
		&-editor {
			.ant-col-12.ant-form-item-control {
				max-width: 100%;
			}
			.tox-editor-container {
				flex-direction: column-reverse !important;
			}

			.tox-toolbar__primary {
				background: unset !important;
			}

			.tox-sidebar-wrap {
				border-bottom: 1px solid #c7c7c7;
			}

			.tox-toolbar__group {
				button span svg {
					font-size: 10px;
					line-height: 12px;
					color: $color-app-base;
				}
			}
			.tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke {
				width: 92px;
			}
			.tox-tinymce {
				@include xs {
					height: 240px;
				}
			}
		}
		&-require {
			color: $color-app-base;
			position: relative;
			margin-bottom: 4px;
			&::before {
				display: inline-block;
				margin-right: 4px;
				color: #ff4d4f;
				font-size: 14px;
				font-family: SimSun, sans-serif;
				line-height: 1;
				content: "*";
			}
		}
		&-btn {
			button {
				margin-right: 8px;
			}
		}
	}
}

.type-link {
	color: #008df9;
	cursor: pointer;
	&:hover {
		color: #2a9f9c;
	}
}

.upload-action {
	.ant-upload {
		position: relative;
	}

	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		width: 100%;
		transition: $trans;
		height: 100%;
		top: 0;
		left: 0;
		svg {
			opacity: 0;
			transition: $trans;
			font-size: 20px;
			color: #fff;
		}
		&:hover {
			background-color: #0000003f;
			svg {
				opacity: 1;
			}
		}
	}
}

/********************************** Admin Filter **********************************/

@include xs {
	.template-box-table {
		& > form {
		
				gap: 16px;
				.template-temp-input {
					width: 100%;
					& .ant-form-item {
						margin: 0;
					}
				}
			
				
				.user-actions {
					width: 100%;
					display: flex;
					align-items: center;
					flex-direction: row;
					justify-content: space-between;
					width: 100%;
					.ant-row {
						flex-basis: 48%;
						margin: 0;
					}
					&__search {
						& .ant-form-item-control-input-content {
							display: flex;
							justify-content: flex-end;
						}
					}
				}
			
			
		}
	}
}

@include sm {
	.template-temp {
		&.admin {
			flex-direction: column;

			.template-temp-input {
				justify-content: space-between;
				align-items: center;
				& > div {
					width: auto;
					margin: 0;
				}
			}
			.user-actions {
				justify-content: space-between;
				margin-top: 12px;

				&__search {
					.ant-form-item-control-input-content {
						display: flex;
						justify-content: flex-end;
					}
				}
			}
		}

		&.member {
			.ant-row {
				margin: 0;
			}
			.template-temp-input {
				flex-basis: 100%;
				flex-direction: column;
				gap: 16px;
				& > div {
					.ant-form-item-label {
						flex-basis: 145px;
					}
				}
			}
			& .user-actions {
				flex-basis: 100%;
				& > div {
					flex-basis: 48%;
				}

				&__search {
					.ant-form-item-control-input-content {
						display: flex;
						justify-content: flex-end;
					}
				}
			}
		}
	}

	/************************** Filter form mail send **************************/

	.template-box-table {
		.mail-send__form {
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;
			&-input {
				display: flex;
				flex-direction: column;
				gap: 16px;
				.ant-row {
					margin: 0;
					.ant-form-item-label {
						flex-basis: 120px;
						margin-right: 8px;
					}
				}
			}
			
			& .user-actions {
				display: flex;
				align-items: center;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				> div {
					flex-basis: 48%;
					margin: 0;
				}

				&__search {
					.ant-form-item-control-input-content {
						display: flex;
						justify-content: flex-end;
					}
				}

				&__clear {
					line-height: 22px;
					color: #707070;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}


@include md {
	.template-temp {
		&.member {
			.ant-row {
				margin: 0;
			}
			.template-temp-input {
				flex-basis: 100%;
				flex-direction: row;
				margin-bottom: 16px;
				justify-content: space-between;
				gap: 16px;
				& > div {
					flex-basis: 48%;
					.ant-form-item-label {
						flex-basis: 86px;
					}
					&:nth-of-type(3) {
						.ant-form-item-label {
							flex-basis: 145px;
						}
					}
				}
			}
		}
	}

	.template-box-table {
		.mail-send__form {
			flex-direction: row;
			align-items: flex-start;
			&-input {
				flex-direction: row;
				.ant-row {
					flex-basis: 48%;
				}
			}
		}
	}
}

@include xl {
	.template-temp {
		&.member {
			.template-temp-input {
				justify-content: flex-start;
				& > div {
					flex-basis: 286px;
					.ant-form-item-label {
						flex-basis: auto;
					}
					&:nth-child(3) {
						flex-basis: 300px;
					}
					&:last-child {
						flex-basis: auto;
						flex-shrink: 0;
					}
				}
			}
			& .user-actions {
				flex-basis: 48%;
				justify-content: flex-end;
				gap: 16px;
				flex-direction: row-reverse;

				& > div {
					flex-basis: auto;
				}
			}
		}
	}
}

@include xxl {
	.template-temp {
		&.admin {
			flex-direction: row;
			gap: 16px;
			.template-temp-input {
				justify-content: space-between;
			}
			.user-actions {
				justify-content: space-between;
				flex-direction: row-reverse;
			}
		}
	}

	.template-box-table {
		.mail-send__form {
			flex-direction: row;
			align-items: flex-start;
			&-input {
				flex-direction: row;
				.ant-row {
					flex-basis: calc((100% - 32px) / 3);
					&.first-to, &.first-subj {
						.ant-form-item-label {
							flex-basis: auto;
						}
					}
					&.app-filter__form--status {
						flex-basis: 210px;
						.ant-form-item-label {
							flex-basis: 120px;
						}
					}
				}
				.times {
					flex-basis: calc((100% - 16px) / 2);
				}
			}
		}
	}
}

.ant-modal-wrap,
.ant-modal-mask {
	z-index: 999999;
}
