.error-page {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.headline {
		float: left;
		font-size: 100px;
		font-weight: 300;
	}

	.error-content {
		// margin-left: 190px;
		text-align: center;
		h3 {
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			color: $color-app-base;
		}
		p {
			font-size: 14px;
			line-height: 22px;
			color: $color-app-heading-sub;
		}
	}
}
