.quick-edit-icon {
  &--check {
    cursor: pointer;
    color: #2a9f9c;
  }
  &--close {
    cursor: pointer;
    color: #6e6b6b;
  }
}
