.mail-send-filter {
  .ant-form-item {
    margin-right: 20px;
  }
  .buttons {
    overflow: hidden;
    .button-clear {
      float: left;
    }
    .button-filter {
      float: right;
    }
  }
}

